<template>
  <div>
    <p class="title-style">新規カテゴリー追加</p>
    <div class="form">
      <el-input
        v-loading="loading.addCategory"
        placeholder="カテゴリーを入力"
        @input="x => $emit('input', x)"
        :value="value"
      />
      <el-button type="primary" @click="$emit('add')" :disabled="isDisabled"
        >追加</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    loading: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDisabled() {
      return this.value.length === 0;
    },
  },
};
</script>

<style scoped lang="scss">
.title-style {
  font-size: 2rem;
}
.form {
  display: flex;
  flex-direction: row;

  & * {
    margin-right: 1rem;
  }

  & *:last-child {
    margin-right: 0;
  }
}
</style>
