<template>
  <div class="page-wrapper">
    <category-form
      :loading="loading"
      :value="value"
      @input="x => $emit('input', x)"
      @add="$emit('add')"
    />
    <category-list
      :categories="categories"
      :loading="loading"
      @edit="x => $emit('edit', x)"
      @del="x => $emit('del', x)"
    />
  </div>
</template>

<script>
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';

export default {
  components: { CategoryForm, CategoryList },
  props: {
    value: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.page-wrapper {
  & * {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
