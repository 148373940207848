<template>
  <el-tabs type="border-card">
    <el-tab-pane v-loading="loading.getRegion" label="地域一覧">
      <div class="card-wrapper">
        <region-list
          :regions="regions"
          :loading="loading"
          @edit="x => $emit('editRegion', x)"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane v-loading="loading.getCategory" label="カテゴリー一覧">
      <div class="card-wrapper">
        <category-page
          :loading="loading"
          :value="category"
          :categories="categories"
          @add="$emit('addCategory')"
          @input="x => $emit('inputCategory', x)"
          @del="x => $emit('delCategory', x)"
          @edit="x => $emit('editCategory', x)"
        />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import CategoryPage from '@/components/CategoryPage.vue';
import RegionList from '@/components/RegionList';
export default {
  name: 'region-category-page',
  components: { CategoryPage, RegionList },
  props: {
    category: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.card-wrapper {
  margin-bottom: 20px;
}
</style>
