<template>
  <div>
    <p id="regions-title">地域一覧・編集</p>
    <div class="region-list-wrapper">
      <el-table :data="regions">
        <el-table-column label="地域名" prop="name">
          <template v-slot:default="scope">
            <el-input
              v-loading="loading.patchRegion"
              v-if="scope.row.id === editItemId"
              ref="editForm"
              v-model="editName"
            />
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="追加日">
          <template v-slot:default="scope">{{
            dateFormat(scope.row.created_at)
          }}</template>
        </el-table-column>
        <el-table-column>
          <template v-slot:default="scope">
            <el-button
              v-if="editItemId === scope.row.id"
              :disabled="editDisable"
              @click="() => edit()"
              icon="el-icon-check"
              type="success"
              circle
            />
            <el-button
              v-else
              :disabled="editItemId !== null"
              @click="() => editing(scope.row)"
              icon="el-icon-edit"
              circle
            />
            <el-button
              v-if="editItemId === scope.row.id"
              @click="() => editCancel()"
              icon="el-icon-close"
              circle
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'region-list',
  props: {
    regions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editItemId: null,
      editName: '',
    };
  },
  computed: {
    editDisable() {
      return this.editName.length === 0;
    },
  },
  methods: {
    dateFormat(date) {
      const y = date.getFullYear();
      const m = (date.getMonth() + 1).toString().padStart(2, '0');
      const d = date
        .getDate()
        .toString()
        .padStart(2, '0');
      return `${y} - ${m} - ${d}`;
    },
    editing(item) {
      this.editItemId = item.id;
      this.editName = item.name;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.focus();
        }
      });
    },
    edit() {
      this.$emit('edit', { id: this.editItemId, name: this.editName });
      this.editCancel();
    },
    editCancel() {
      this.editItemId = null;
      this.editName = '';
    },
  },
};
</script>

<style lang="scss" scoped>
#regions-title {
  font-size: 2rem;
}
</style>
