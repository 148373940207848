<template>
  <category-region-page
    :loading="loading"
    :category="newCategory"
    :categories="categories"
    :regions="regions"
    @inputCategory="changeValue"
    @addCategory="addCate"
    @editCategory="editCate"
    @delCategory="delCate"
    @editRegion="editReg"
  />
</template>

<script>
import CategoryRegionPage from '@/components/RegionCategoryPage';
import { mapState, mapActions } from 'vuex';
import { ActionTypes as AttributeActions } from '@/store/modules/attribute/actions';
export default {
  components: { CategoryRegionPage },
  data() {
    return {
      newCategory: '',
    };
  },
  computed: {
    ...mapState('attribute', ['categories', 'regions', 'loading']),
  },
  methods: {
    ...mapActions('attribute', [
      AttributeActions.getCategories,
      AttributeActions.addCategory,
      AttributeActions.editCategory,
      AttributeActions.deleteCategory,
      AttributeActions.getRegions,
      AttributeActions.patchRegion,
    ]),
    changeValue(input) {
      this.newCategory = input;
    },
    async addCate() {
      this.addCategory({ name: this.newCategory });
    },
    async editCate(payload) {
      await this.editCategory(payload);
    },
    async delCate({ id }) {
      await this.deleteCategory(id);
    },
    editReg(form) {
      this.patchRegion(form);
    },
  },
  created() {
    this.getCategories();
    this.getRegions();
  },
};
</script>

<style></style>
